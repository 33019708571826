import React from "react";
import { Segment, Table, Header, Container, Icon, Button } from 'semantic-ui-react';
import TradingViewChart from "./TradingViewChart";
import { useApiPoll } from "./call-api";
import LastUpdate from "./LastUpdate";
import * as helpers from "./helpers";
import { TrackEvent } from "./Tracking";
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import { useHistory } from "react-router-dom"

const ONE_HOUR = 60 * 60;
const SIX_HOURS = ONE_HOUR*6;
const TWENTY_FOUR_HOURS = ONE_HOUR*24;
const FOURTY_EIGHT_HOURS = ONE_HOUR*48;


const getSparkData = (dataSet) => {
    dataSet = dataSet.map((obj) => {
        return obj.value;
    })
    return dataSet.reverse()
}

const calcDiff = (current, previous) => {
    if (previous.value == 0) { return 0 }
    return (current.value - previous.value) / Math.abs(previous.value)*100;
}

const getDeltas = (dataSet) => {
    var oneHour = 0;
    var oneHourSet = false;
    var sixHour = 0;
    var sixHourSet = false;
    var oneDay = 0;
    var oneDaySet = false;
    var fourtyEightHours = 0;
    var fourtyEightHoursSet = false;


    if (dataSet.length == 0) {
        return {
            oneHour,
            sixHour,
            oneDay,
            fourtyEightHours,
        }
    }
    dataSet = dataSet.reverse()
    const current = dataSet[0];
    dataSet.slice(1).forEach((obj) => {
        if (oneHourSet && sixHourSet && oneDaySet && fourtyEightHoursSet) { return };

        if (!oneHourSet && obj.time >= current.time - ONE_HOUR) {
            oneHour = calcDiff(current, obj);
            oneHourSet = true;
        }

        if (!sixHourSet && current.time - SIX_HOURS >= obj.time) {
            sixHour = calcDiff(current, obj);
            sixHourSet = true;
        }

        if (!oneDaySet && current.time - TWENTY_FOUR_HOURS >= obj.time) {
            oneDay = calcDiff(current, obj);
            oneDaySet = true;

        }

        if (!fourtyEightHoursSet && current.time - FOURTY_EIGHT_HOURS <= obj.time) {
            fourtyEightHours = calcDiff(current, obj);
            fourtyEightHoursSet = true;
        }
    })
    return {
        oneHour,
        sixHour,
        oneDay,
        fourtyEightHours
    }
}

const Percent = ({value}) => {
    var color = value >= 0 ? "green" : "red";
    const operator = value >= 0 ? "+" : "-";
    return (
        <Header as='h4' color={color} >
            { `${operator}${Math.abs(value.toFixed(2))}%` }
        </Header>
    )
}

const ScanRow = ({ evaluation }) => {
    const history = useHistory()
    const path = "/evaluations/" + evaluation.evaluation_id;
    const [pollState] = useApiPoll(path, undefined, false);

    const handleChartClick = (loc) => {
        history.push(loc)
        helpers.track("ScansListClicked", { path: loc })
    };

    if (pollState.isFetching) {
        return <Segment loading/>
    }


    if (pollState.error) {
        console.log(`error with ${path}`)
        return null
    }

    let data = helpers.serializeEvaluationForChart(pollState.data);
    if (data.length == 0) {
        return null
    }
    const latest = data[0].value || 0;
    const color = latest > 0 ? "green" : "red";
    const deltas = getDeltas(data)

    return (
        <Table.Row onClick={() => handleChartClick(`/scans/${evaluation.evaluation_id}`)}>
            <Table.Cell>
                <Header as="h4">
                    { evaluation.subject_label }
                    <Header.Subheader>Reddit</Header.Subheader>
                </Header>
            </Table.Cell>
            {/* <Table.Cell width={2}>
                < Percent value={deltas.oneHour} />
            </Table.Cell>
            <Table.Cell width={2}>
                < Percent value={deltas.sixHour} />
            </Table.Cell>
            <Table.Cell width={2}>
                < Percent value={deltas.oneDay} />
            </Table.Cell>
            <Table.Cell width={2}>
                < Percent value={deltas.fourtyEightHours} />
            </Table.Cell> */}
            <Table.Cell width={2}>
                <Sparklines data={getSparkData(data)} limit={10}>
                    <SparklinesReferenceLine type="mean" />
                    <SparklinesLine color="blue"/>
                </Sparklines>
            </Table.Cell>
        </Table.Row>
    )
}

const Scans = () => {
    const path = "/evaluations"
    const [pollState] = useApiPoll(path, 1000000, false);
    if (pollState.isFetching) {
        return <Segment loading/>
    }

    let evaluations = pollState.data;

    evaluations = evaluations.sort((a, b) => a.subject_label.localeCompare(b.subject_label))

    return (
        <TrackEvent eventName="ScansListViewed">
            <Container>
                <Table selectable celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Source</Table.HeaderCell>
                            {/* <Table.HeaderCell>1H</Table.HeaderCell>
                            <Table.HeaderCell>6H</Table.HeaderCell>
                            <Table.HeaderCell>24H</Table.HeaderCell>
                            <Table.HeaderCell>48H</Table.HeaderCell> */}
                            <Table.HeaderCell>Chart</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {evaluations.map((evaluation, idx) => {
                            return <ScanRow evaluation={evaluation} key={idx}/>
                        })}
                    </Table.Body>
                </Table>
            </Container>
        </TrackEvent>
    )
}
export default Scans;