import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as Tv from 'lightweight-charts';
import { useDimensions } from "./helpers";

const TradingViewChart = ({ label, data }) => {
    const [dims, containerRef] = useDimensions({
        width: 300,
        height: 170
    });
    const [chart, setChart] = useState(undefined);
    const [lineSeries, setLineSeriese] = useState(undefined);
    const chartRef = useRef()

    useEffect(() => {
        if (chart == undefined) {
            const myChart = Tv.createChart(chartRef.current, { 
                width: dims.width,
                height: dims.height
            });
            myChart.applyOptions({
                timeScale: {
                    timeVisible: true,
                    visible: true
                },
                watermark: {
                    color: 'rgba(11, 94, 29, 0.3)',
                    visible: true,
                    text: label,
                    fontSize: 24,
                    horzAlign: 'center',
                    vertAlign: 'center',
                },
            });
            setChart(myChart)
            const myLineSeriese = myChart.addAreaSeries();
            myLineSeriese.setData(data);
            setLineSeriese(myLineSeriese)
        }
    }, []);

    useEffect(() => {
        if (lineSeries != undefined) {
            lineSeries.setData(data);
        }
    }, [lineSeries, data])

    useEffect(() => {
        if (chart != undefined) {
            chart.resize(dims.height, dims.width);
        }
    }, [dims])

    return (
        <div style={{ display: "flex" }}>
            <div ref={containerRef} style={{ width: "100%", margin: "0 auto" }}>
                <div ref={chartRef}/>
            </div>
        </div>
    )
}

export default TradingViewChart;