import React, { Component, useEffect, useReducer } from 'react';
// import { useAuth0 } from "./react-auth0-wrapper";
// import config from "./config";

const API_BASE = process.env.REACT_APP_API_BASE || "http://localhost:1337";


const CallApi = async (base_url, path, token) => {
  let out = {
    error: undefined
  }
  try {
    const url = base_url + path
    const headers = {}
    if (token != undefined) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(url, {
      headers: headers
    })
    if (response.status > 300) {
      throw new Error("Api returned", response.status)
    }
    
    out.data = await response.json()
  } catch (err) {
    console.log("CallApi" + err);
    out.error = err
  }
  return out;
};

export default CallApi;

const defaultState = {
  requestId: 0,
  data: null,
  isFetching: true,
  error: null,
  timestamp: 0
};

function reducer(state, action) {
  switch (action.type) {
    case "fetched":
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        timestamp: Math.floor(Date.now() / 1000)
      };
    case "error":
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case "poll":
      return {
        ...state,
        requestId: state.requestId + 1
      };
    default:
      return state;
  }
}

// TODO: replace with auth
const getTokenSilently = () => { return true }

export const useApiPoll = (path, pollInterval, protectedRoute, base_url) => {
  // https://medium.com/swlh/fetching-api-data-with-react-hooks-hoc-or-render-prop-ec6b37aa5a87
  const [state, dispatch] = useReducer(reducer, defaultState);
//   const { getTokenSilently } = useAuth0();
  
  if (base_url == undefined) {
    base_url = API_BASE
  }
  
  const getUpdate = async (urlPath) => {
    dispatch({ type: "poll" });
    var authToken = undefined
    if (protectedRoute) {
      authToken = await getTokenSilently()
    }
    let response = await CallApi(base_url, urlPath, authToken)
    if (response.error != undefined) {
      dispatch({ type: "error", payload: response.error });
      return
    }
    dispatch({ type: "fetched", payload: response.data });
  };

  useEffect(() => {
    getUpdate(path)
  }, [path]);

  useEffect(() => {
    if (!pollInterval || state.isFetching) return;
    const timeoutId = setInterval(() => {
      getUpdate(path)
    }, pollInterval);

    return () => {
      clearInterval(timeoutId);
    };
  }, [pollInterval, state.isFetching]);
  
  let pollState = state;
  return [pollState];
}
