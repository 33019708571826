import React from "react";
import { Segment, Header, Container, Button } from 'semantic-ui-react';
import * as helpers from "./helpers";
import { useApiPoll } from "./call-api";
import TradingViewChart from "./TradingViewChart";
import { TrackEvent } from "./Tracking";



const Scan = ({ label, data }) => {
    if (!data) {
        return <Segment loading/>;
    }

    let chart = helpers.serializeEvaluationForChart(data);
    label = label + " sentiment"
    return (
        <TrackEvent eventName="ScanChartViewed">
            <Container>
                <TradingViewChart data={chart} label={label}/>
            </Container>
        </TrackEvent>
    )
}

export default Scan;