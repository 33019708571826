import React from "react";
import { useHistory } from "react-router-dom"
import {
  Container,
  Menu,
  Segment,
  Icon,
  Dropdown,
  List,
  Divider
} from 'semantic-ui-react'
import * as helpers from "./helpers";

function PageHader() {
  const history = useHistory()
  const handleRedirect = (event) => {
    const loc = `/${event.target.id}`
    helpers.track("NavItemClicked", { label: loc })
    history.push(loc)
  }
  return (
    <React.Fragment>
      <Menu fixed='top' inverted>
        <Container>
          <Menu.Item onClick={handleRedirect} id="" header>
            <Icon name='eye' size="large"/>
            Disentinel
          </Menu.Item>
          <Menu.Item onClick={handleRedirect} id="create/scans">
            Create Analysis
          </Menu.Item>
          <Menu.Menu position='right'>
            <Dropdown item icon='wrench' simple>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleRedirect} id="register">Sign In</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Container>
      </Menu>
    </React.Fragment>
  )
}

function PageFooter() {
  return (
    <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
        <Container textAlign='center'>
          <Container>
            <Icon name='eye' size="large"/>
          </Container>
          <Container>
            <List horizontal inverted divided link size='small'>
              <List.Item as='a' href='https://www.octaviuslabs.com'>
                Made in Los Angeles by Octavius Labs
              </List.Item>
            </List>
          </Container>
          {/* <Divider inverted section />
          <Container>
            <List horizontal inverted divided link size='small'>
              <List.Item as='a' href='mailto:'>
                Contact Us
              </List.Item>
              <List.Item as='a' href='#'>
                Terms and Conditions
              </List.Item>
              <List.Item as='a' href='#'>
                Privacy Policy
              </List.Item>
            </List>
          </Container> */}
        </Container>
    </Segment>
  )
}

function UserInterface({children}) {
  const styles = {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    marginTop: '7em'
  }
  return (
    <div>
      <PageHader/>
        <Container style={styles}>
          { children }
        </Container>
        <PageFooter/>
    </div>
  );
}

export default UserInterface;
