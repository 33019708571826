import React, { useEffect } from "react";
import * as helpers from "./helpers";
import { useLocation } from 'react-router-dom';

export const TrackPage = ({ children }) => {
    let location = useLocation();
    useEffect(() => {
        helpers.page(location.pathname)
    }, [location]);
  
    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    )
}

export const TrackEvent = ({ children, eventName, params }) => {
    useEffect(() => {
        helpers.track(eventName, params)
    }, [])

    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    )
}