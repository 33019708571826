import React, { useContext, useReducer } from "react";
import { Form, Button, Segment, Header, Grid, Message } from 'semantic-ui-react';
import * as helpers from "./helpers";
import { TrackEvent } from "./Tracking"

const FormContext = React.createContext();

function reducer(state, action) {
    switch (action.type) {
        case "UPDATE_IDENTITY":
            return {
                ...state,
                identity: action.payload
            }
        case "PENDING":
            return {
                ...state,
                isLoading: true
            };
        case "DONE": 
            return {
                ...state,
                isLoading: false
            };
        case "HAS_SUBMITTED":
            return {
                ...state,
                hasSubmitted: 1
            };
        default:
            return state;
    }
  }

const BetaForm = ({ hasSubmitted }) => {
    const { state, dispatch} = useContext(FormContext)
    const identity = state.identity;

    if (state.hasSubmitted) {
        return <TrackEvent eventName="UserAlreadyRegistered"/>;
    }

    const onChange = (event) => {
        const target = event.target.id
        const newIdentity = Object.assign({}, identity)
        newIdentity[target] = event.target.value
        dispatch({ type: "UPDATE_IDENTITY", payload: newIdentity})
    }

    const sendData = (input) => {
        dispatch({ type: "PENDING"});
        helpers.identify(identity.email, identity)
        helpers.track("UserBetaRequested", { email: identity.email })
        window.localStorage.setItem("hasRegistedBeta", 1)
        dispatch({ type: "DONE"});
        dispatch({ type: "HAS_SUBMITTED"})
        helpers.track("UserRegistered")
        return
    }

    return (
        <TrackEvent eventName="UserRegistrationViewed">
            <Form loading={state.isLoading} onSubmit={sendData} onChange={onChange} disabled>
                <Form.Input
                fluid
                id="firstName"
                label='First name'
                placeholder='First name'
                required
                />
                <Form.Input
                fluid
                id="lastName"
                label='Last name'
                placeholder='Last name'
                required
                />
                <Form.Input
                fluid
                id="email"
                label='Email Address'
                placeholder='your@email.com'
                type="email"
                required
                />
                <Button type='submit'>Register</Button>
            </Form>
        </TrackEvent>
    )
}


const BetaSignup = () => {
    const initState = {
        isLoading: false,
        // TODO: store identity in localstorage
        hasSubmitted: window.localStorage.getItem("hasRegistedBeta"),
        identity: {
            source: "betaSignup",
            firstName: "",
            lastName: "",
            email: ""
        }
    };
    const [state, dispatch] = useReducer(reducer, initState);

    let subHeader = "If you would like to be notified when a beta slot becomes avaiable fill out the below form."
    if (state.hasSubmitted) {
        subHeader = "You have already applied to the closed beta. We will contact you when an open beta slot is avaiable."
    }

    return (
        <FormContext.Provider value={{state, dispatch}}>
            <Message warning color="red" header='You must register before you can do that!'/>
            <Segment attached>
                <Grid textAlign='center' style={{ height: '50vh' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header textAlign="center">
                            Currently Disentinel is in closed beta!
                            <Header.Subheader>{ subHeader }</Header.Subheader>
                        </Header>
                        <BetaForm/>
                    </Grid.Column>
                </Grid>
            </Segment>
        </FormContext.Provider>
    )
}

export default BetaSignup;
