import React, { useEffect } from "react";
import UserInterface from "./UserInterface";
import Scans from "./Scans";
import Scan from "./Scan";
import Rules from "./Rules";
import * as helpers from "./helpers";
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Drift from "react-driftjs";
import Config from "./config";
import BetaSignup from "./BetaSignup"
import { TrackPage } from "./Tracking";
import Helmet from "./Helmet";

const GetDrift = ({ children }) => {
  if (!helpers.isProduction()) {
    return (
      <React.Fragment>
        { children }
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Drift appId={Config.driftKey} />,
      { children }
    </React.Fragment>
  )
}

function App() {
  return (
    <BrowserRouter>
        <Helmet/>
        <GetDrift>
          <TrackPage>
            <UserInterface>
              <Switch>
              <Route exact path="/">
                <Redirect path="/" to="/scans"/>
              </Route>

              <Route exact path="/scans" component={Scans}/>
              <Route path="/scans/:id" component={Scan}/>
              <Route path="/register" component={BetaSignup}/>
              <Redirect path="/create/scans/" to="/register"/>
              </Switch>
            </UserInterface>
          </TrackPage>
        </GetDrift>
    </BrowserRouter>
  );
}

export default App;
