import Config from "./config";
import { useState, useCallback } from 'react';


const SPACER = 60; // every minute
export const makeId = (inString) => {
    return inString.replace("/", "-")
}

const calculateMovingAverage = (data, periods) => {
    const values = [];
    return data.map((obj) => { 
        if (values.length > periods) {
            // take first item off
            values.shift()
        }

        // push item on
        values.push(obj.value)

        const ave = values.reduce((a, b) => a + b, 0) / values.length  
        return { time: obj.time, value: parseFloat(ave) }
    });
}

export const serializeEvaluationForChart = (evaluation) => {
    let data = evaluation.sort(function(a, b){return b.timestamp-a.timestmp});

    data = data.map((obj) => { 
        return { time: Math.floor(obj.timestamp), value: parseFloat(obj.total) }
    });
    return data;
}

export const serializeChartData = (dataObj) => {
    return Object.keys(dataObj).map((key) => {
        const ele = dataObj[key];
        let data = ele.map((obj) => { 
            return { time: Math.floor(obj.timestamp), value: parseFloat(obj.score) }
        });

        data = data.sort(function(a, b){return b.timestamp-a.timestmp});
        return {
            label: key,
            id: makeId(key),
            data: data
        }
    });
}

export const isProduction = () => {
    return (Config.environment == "production")
}

export const identify = (userId, params) => {
    if (isProduction()) {
        window.analytics.identify(userId, params);
    } else {
        console.log("Identify", userId, params);
    }
}


export const track = (eventName, params) => {
    if (isProduction()) {
        window.analytics.track(eventName, params);
    } else {
        console.log("Event", eventName, params);
    }
}

export const page = (pageName, params) => {
    if (isProduction()) {
        window.analytics.page(pageName, params)
    } else {
        console.log("Page", pageName, params);
    }
}

export const useDimensions = ({ width, height}) => {
    const [dims, setDims] = useState({
        width: width,
        height: height
    });
    
    const adjustDims = (node) => {
        let rect = node.getBoundingClientRect()
        const width = rect.width;
        const height = Math.floor(width/1.77);
        setDims({
            width,
            height
        });
    }

    const containerRef = useCallback(node => {
        if (node !== null) {
            adjustDims(node)
        }
    }, []);


    return [dims, containerRef]
};

export const capitalizeFirstLetter = (inStr) => {
    return inStr.charAt(0).toUpperCase() + inStr.slice(1);
}