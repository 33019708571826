import React from "react";
import { Helmet } from "react-helmet";

const MyHelmet = () => {
    const fixedTitle = "Disentinel - Cryptoasset Sentiment";
    const fixedDescription = "Sentiment data on the top cryptocurrencies and cryptoassets";

    return (
        <Helmet>
            <title>{ fixedTitle } </title>
            <meta name="description" content={fixedDescription} />

            {/* Schema.org markup for Google+ */}
            <meta itemprop="name" content={ fixedTitle }/>
            <meta itemprop="description" content={ fixedDescription }/>
            {/* <meta itemprop="image" content="http://www.example.com/image.jpg"/> */}

            {/* Twitter Card data */}
            {/* <meta name="twitter:card" content="summary_large_image"/> */}
            <meta name="twitter:site" content="@disentinel"/>
            <meta name="twitter:title" content={ fixedTitle }/>
            <meta name="twitter:description" content={fixedDescription}/>
            <meta name="twitter:creator" content="@disentinel"/>
            
            {/* Twitter summary card with large image must be at least 280x150px */}
            {/* <meta name="twitter:image:src" content="http://www.example.com/image.jpg"/> */}

            {/* Open Graph data */}
            <meta property="og:title" content={fixedTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="http://app.disentinel.com/" />
            {/* <meta property="og:image" content="http://example.com/image.jpg" /> */}
            <meta property="og:description" content={fixedDescription} />
            <meta property="og:site_name" content={fixedTitle} />
            {/* <meta property="fb:admins" content="Facebook numberic ID" />  */}
        </Helmet>
    )
}

export default MyHelmet;