import React from "react";
import { Tab, Container, Header, Grid, Icon, Segment, Label } from 'semantic-ui-react';
import Rules from "./Rules";
import ScanChart from "./ScanChart";
import { useParams } from "react-router-dom"
import * as helpers from "./helpers";
import { useApiPoll } from "./call-api";


const MyTabs = ({ evaluation }) => {
    const panes = [
        {
          menuItem: 'Chart',
          render: () => { 
              return ( 
                <Tab.Pane attached={false}>
                    <ScanChart label={evaluation.subject} data={evaluation.data} />
                </Tab.Pane>
              )
          },
          resource: "/scans/:id"
        },
        {
          menuItem: 'Rules',
          render: () => { 
              return ( 
                <Tab.Pane attached={false}>
                    <Rules id={evaluation.id}/>
                </Tab.Pane>
              )
          },
          resource: "/scans/rules/:id"
        },
    ];
    return (
        <Tab
            menu={{ borderless: true, attached: false, tabular: false }}
            panes={panes}
        />
    )
}

const Scan = () => {
    const { id } = useParams();
    const path = "/evaluations/" + id;
    const [pollState] = useApiPoll(path, 1000000, false);

    if (pollState.isFetching) {
        return <Segment loading/>;
    }
    const data = pollState.data;

    if (data.length == 0) {
        return null
    }
    const evaluation = {
        source: data[0].source,
        subject: data[0].subject,
        id: id,
        data: data
    }

    return (
        <Container>
            <Segment color="green">
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Header Header as="h2">{helpers.capitalizeFirstLetter(evaluation.source)} {helpers.capitalizeFirstLetter(evaluation.subject)} Sentiment</Header>
                        </Grid.Column>
                        <Grid.Column width={5} textAlign="right">
                        <div>
                            <a href="/register" onClick={() => helpers.track("ScanAddOverlayClick")}>
                                <Label color="blue">
                                    <Icon link name="copy outline"/> Add Overlay
                                </Label>
                            </a>
                            <a href="/register" onClick={() => helpers.track("ScanCloneRulesClick")}>
                                <Label color="green">
                                    <Icon link name="copy outline"/> Clone Rules
                                </Label>
                            </a>
                        </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <MyTabs evaluation={evaluation}/>
        </Container>
    )
}

export default Scan;