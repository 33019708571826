import React from "react";
import { Segment, Table } from 'semantic-ui-react';
import { useApiPoll } from "./call-api";
import * as helpers from "./helpers";
import { TrackEvent } from "./Tracking";

const Rules = ({ id }) => {
    let path = "/rules"
    path = path + "/" + id;
    const [pollState] = useApiPoll(path, undefined, false);

    if (pollState.isFetching) {
        return <Segment loading/>
    }

    return (
        <TrackEvent eventName="ScanRulesViewed">
            <Table celled attached>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Word</Table.HeaderCell>
                    <Table.HeaderCell>Rule</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                    <Table.HeaderCell>Similarity</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    { pollState.data.map((ele, idx) => {
                        return (
                            <Table.Row key={idx}>
                                <Table.Cell>{ele.word}</Table.Cell>
                                <Table.Cell>{ele.rule}</Table.Cell>
                                <Table.Cell>{ele.value}</Table.Cell>
                                <Table.Cell>{ele.similarity}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </TrackEvent>
    )
}

export default Rules;